// import { getWebsiteOrigin } from '@aether/utils/services/linking-service';
import { Navigate } from 'react-router-dom';

import { useAbilityContext, useAuth } from '@/utils/auth';

export const Home = () => {
  const { isAuthenticated, fetchingUser } = useAuth();
  const ability = useAbilityContext();

  // wait for the user and the feature flag before redirecting
  if (fetchingUser) return;

  if (!isAuthenticated) {
    // const website = getWebsiteOrigin() || '/';
    // location.replace(website.includes('.arabesque.') ? website : '/auth/login');
    location.replace('/auth/login');
  } else
    return (
      <Navigate replace to={ability.can('read', 'DashboardPage') ? '/dashboard/favourites' : '/analyze-portfolio'} />
    );

  return null;
};
