import React, { useState } from 'react';
import { Alert, Button, Card, Checkbox } from '@aether/ui';
import { ApolloError } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { useAuth } from '@/utils/auth';

import { AuthLink } from '../components/AuthLink';
import { TermsAndConditions } from '../Register/TermsAndConditions';

export const ActionSignIn: React.FC<{
  continueUrl: string;
}> = ({ continueUrl }) => {
  const { signInWithEmailLink, requestNewInvite } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [tcVisible, setTcVisible] = useState(false);
  const [tcAccepted, setTcAccepted] = useState(false);
  const [newEmailSent, setNewEmailSent] = useState(false);

  const params = new URLSearchParams(continueUrl.substr(continueUrl.indexOf('?')));
  const linkEmail = params.get('m1');

  const signIn = async () => {
    setLoading(true);
    try {
      await signInWithEmailLink(linkEmail!, window.location.href);
      navigate('/account/complete-signup', { replace: true });
    } catch (e: any) {
      setError(e);
      setLoading(false);
    }
  };

  const handleRequestNewInvite = async () => {
    if (!linkEmail) return;
    setError(undefined);
    setLoading(true);
    try {
      await requestNewInvite(linkEmail);
      setNewEmailSent(true);
    } catch (e: any) {
      setNewEmailSent(true);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card variant="hero" title="Sign up to Portfolio Dragon." className="m-20 -mx-4 max-w-4xl sm:mx-auto">
      {newEmailSent ? (
        <Alert
          className="mt-8"
          level="info"
        >{`A new invitation link should have been sent to your email (${linkEmail}). Please follow this new link to sign up.`}</Alert>
      ) : !error ? (
        <div className="mt-4 space-y-4">
          <p>
            Please read & agree to our <a onClick={() => setTcVisible(true)}>terms and conditions</a> to accept the
            invitation and sign up to the web app.
          </p>
          <p>
            <Checkbox
              checked={tcAccepted}
              onCheckedChange={checked => setTcAccepted(Boolean(checked))}
              className="text-sm"
            >
              I have read and agree to the{' '}
              <AuthLink
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTcVisible(true);
                }}
                asChild
              >
                <a>Terms and Conditions.</a>
              </AuthLink>
            </Checkbox>
          </p>
          <TermsAndConditions isVisible={tcVisible} setVisible={setTcVisible} />
          <div>
            <Button onClick={signIn} disabled={!tcAccepted} loading={loading} loadingText="Signing up...">
              Accept invitation & Sign up
            </Button>
          </div>
        </div>
      ) : undefined}

      {error && <ApiErrorAlert error={error} className="mt-4" />}
      {error instanceof ApolloError &&
        JSON.stringify(error.networkError).includes('Invalid or expired action code') && (
          <Button className="mt-4" onClick={handleRequestNewInvite} loading={loading}>
            Request new sign up link
          </Button>
        )}
    </Card>
  );
};
