import React from 'react';
import { Button, Card } from '@aether/ui';
import { Link, useNavigate } from 'react-router-dom';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { useActionCode } from '@/utils/auth';

export const ActionVerifyEmail: React.FC<{ actionCode: string; continueUrl: string }> = ({
  actionCode,
  continueUrl,
}) => {
  const { data, loading, error } = useActionCode(actionCode);
  const decodedContinueURL = decodeURIComponent(continueUrl);
  const initialEmail = decodedContinueURL.substr(decodedContinueURL.indexOf('email=') + 'email='.length);
  const navigate = useNavigate();

  return (
    <>
      {error ? (
        <Card variant="hero" title="Error." className="m-20 -mx-4 max-w-4xl sm:mx-auto">
          <div className="flex flex-col gap-4">
            <p>Failed to verify your email address ({initialEmail}).</p>
            <ApiErrorAlert error={error} />
            <div>
              <Button asChild>
                <Link to={`/auth/email-verification`}>Request another verification e-mail</Link>
              </Button>
            </div>
          </div>
        </Card>
      ) : (
        <Card
          variant="hero"
          title="Your email has been verified."
          className="-mx-4 max-w-3xl sm:mx-auto"
          loading={loading}
        >
          <div className="flex flex-col gap-4">
            <p>You may now log in to your account</p>
            <div>
              <Button asChild>
                <Link
                  to={`/auth/login?email=${initialEmail}${data?.data?.applyActionCode.success && data?.data?.applyActionCode.navigate ? `&redirectTo=${data?.data?.applyActionCode.navigate}` : ''}`}
                >
                  Go to Log in
                </Link>
              </Button>
            </div>
          </div>
        </Card>
      )}
      <ConfirmDialog
        alertDialogRootProps={{ open: data?.data?.applyActionCode.success === false }}
        title="We encountered an error"
        body={data?.data?.applyActionCode.message || 'The action you performed was unsuccessful, please try again.'}
        onConfirm={() => navigate(`/auth/login?email=${initialEmail}`)}
        onCancel={() => navigate(`/auth/login?email=${initialEmail}`)}
        confirmText="Continue"
        cancelProps={{ className: 'hidden' }}
      />
    </>
  );
};
