import * as React from 'react';
import {
  type ApplyActionCodeMutationMutation,
  type LoginMutationMutation,
  type RegisterInput,
  type RequestNewInviteMutationMutation,
  type ResetPasswordMutationMutation,
  type SendResetPasswordMutationMutation,
  type SendVerificationEmailMutationMutation,
  type VerifyPasswordResetCodeMutationMutation,
} from '@aether/client-graphql/generated/graphql';
import { resetTracking, tracker } from '@aether/tracking';
import { NullKeysToUndefined } from '@aether/utils';
import { getUserAbility, type AfterAuth, type Rule } from '@aether/validation';
import { type FetchResult } from '@apollo/client';
import { createContextualCan } from '@casl/react';
import * as Sentry from '@sentry/react';

import { graphql, useMutation, useQuery, type DocumentType } from '@graphql';
import { getApolloClient } from '@/graphql/client';

export type AuthUser = DocumentType<typeof AuthUserFragment>;

type AuthContextType = {
  user?: AuthUser;
  fetchingUser: boolean;
  refetchUser: () => Promise<AuthUser>;
  signInWithEmailAndPassword: ({
    email,
    password,
    afterAuth,
  }: {
    email: string;
    password: string;
    afterAuth?: AfterAuth;
  }) => Promise<FetchResult<LoginMutationMutation>>;
  signInWithEmailLink: (email: string, link: string) => Promise<AuthUser>;
  signOut: () => Promise<any>;
  sendVerificationEmail: () => Promise<FetchResult<SendVerificationEmailMutationMutation>>;
  register: (input: RegisterInput) => Promise<AuthUser>;
  sendResetPasswordEmail: (email: string) => Promise<FetchResult<SendResetPasswordMutationMutation>>;
  verifyPasswordResetCode: (oobCode: string) => Promise<FetchResult<VerifyPasswordResetCodeMutationMutation>>;
  resetPassword: (input: {
    newPassword: string;
    oobCode: string;
  }) => Promise<FetchResult<ResetPasswordMutationMutation>>;
  requestNewInvite: (email: string) => Promise<FetchResult<RequestNewInviteMutationMutation>>;
  isAuthenticated: boolean;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);
const defaultPermissions: Rule[] = [];
const AbilityContext = React.createContext(getUserAbility(defaultPermissions));

export const useAbilityContext = () => {
  const context = React.useContext(AbilityContext);
  if (context === undefined) {
    throw new Error('useAbilityContext must be used within an AbilityContext.Provider');
  }
  return context;
};
export type Ability = ReturnType<typeof useAbilityContext>;
export const Can = createContextualCan(AbilityContext.Consumer);

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export function AuthProvider({
  children,
  loadingPlaceholder,
}: {
  children: React.ReactNode;
  loadingPlaceholder?: React.ReactNode;
}) {
  const { data, loading, refetch } = useQuery(authQuery, { fetchPolicy: 'cache-and-network' });
  const user = data?.me;
  const ability = getUserAbility(
    user?.permissions
      ? user.permissions.map(({ __typename, ...permission }) => NullKeysToUndefined(permission))
      : defaultPermissions
  );

  const refetchUser = React.useCallback(async () => refetch().then(({ data }) => data.me), [refetch]);

  const apolloClient = getApolloClient();
  React.useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, email: user.email });
    }
    if (user && user.role !== 'public') {
      tracker.identify(user.clientId, { role: user.role, email: user.email });
    }
  }, [user]);

  const signInWithEmailAndPassword = React.useCallback(
    async ({ email, password, afterAuth }: { email: string; password: string; afterAuth?: AfterAuth }) => {
      const res = await apolloClient.mutate({ mutation: loginMutation, variables: { email, password, afterAuth } });
      await refetchUser();
      return res;
    },
    [apolloClient, refetchUser]
  );

  const signInWithEmailLink = React.useCallback(
    async (email: string, link: string) => {
      return apolloClient
        .mutate({ mutation: signInWithEmailLinkMutation, variables: { email, link } })
        .then(refetchUser);
    },
    [apolloClient, refetchUser]
  );

  const register = React.useCallback(
    async (input: RegisterInput) => {
      return apolloClient.mutate({ mutation: registerMutation, variables: { input } }).then(refetchUser);
    },
    [apolloClient, refetchUser]
  );

  const signOut = React.useCallback(
    async () =>
      apolloClient.mutate({ mutation: logoutMutation }).finally(() => {
        ability.update([]);
        resetTracking();
        window.location.replace('/');
      }),
    [ability, apolloClient]
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        fetchingUser: loading,
        refetchUser,
        signInWithEmailAndPassword,
        signInWithEmailLink,
        signOut,
        sendVerificationEmail,
        register,
        sendResetPasswordEmail,
        verifyPasswordResetCode,
        requestNewInvite,
        resetPassword,
        isAuthenticated: !!user && user.role !== 'public',
      }}
    >
      <AbilityContext.Provider value={ability}>
        {loading && !data && loadingPlaceholder ? loadingPlaceholder : children}
      </AbilityContext.Provider>
    </AuthContext.Provider>
  );
}

async function sendVerificationEmail() {
  const client = getApolloClient();
  return client.mutate({ mutation: sendVerificationEmailMutation });
}

async function sendResetPasswordEmail(email: string) {
  const client = getApolloClient();
  return client.mutate({ mutation: sendResetPasswordMutation, variables: { email } });
}

async function verifyPasswordResetCode(oobCode: string) {
  const client = getApolloClient();
  return client.mutate({ mutation: verifyPasswordResetCodeMutation, variables: { oobCode } });
}

async function resetPassword(variables: { newPassword: string; oobCode: string }) {
  const client = getApolloClient();
  return client.mutate({ mutation: resetPasswordMutation, variables });
}

async function requestNewInvite(email: string) {
  const client = getApolloClient();
  return client.mutate({ mutation: requestNewInviteMutation, variables: { email } });
}

export function useActionCode(oobCode: string) {
  const hasRunOnce = React.useRef(false);
  const data = React.useRef<FetchResult<ApplyActionCodeMutationMutation>>();
  const { refetchUser } = useAuth();
  const [applyActionCode, { loading, error }] = useMutation(applyActionCodeMutation);

  React.useEffect(() => {
    if (hasRunOnce.current) return undefined;
    hasRunOnce.current = true; // hack to prevent react-18 to run this effect twice in dev
    applyActionCode({ variables: { oobCode } }).then(res => {
      refetchUser();
      data.current = res;
    });
  }, [oobCode, refetchUser, applyActionCode]);
  return { loading, error, data: data.current };
}

const AuthUserFragment = graphql(/* GraphQL */ `
  fragment AuthUser on User {
    id
    clientId
    role
    displayName
    email
    emailVerified
    country
    profession
    passwordSet
    photoURL
    lastSignInTime
    declaredOrg
    createdAt
    permissions {
      action
      subject
      fields
      conditions
      inverted
      reason
    }
    isAdmin
  }
`);

// The query name `AuthQuery` is used to fetch the persisted query in the
// dragon app (apps/dragon/app/root.tsx), so it should not be changed
const authQuery = graphql(/* GraphQL */ `
  query AuthQuery {
    me {
      ...AuthUser
    }
  }
`);

const loginMutation = graphql(/* GraphQL */ `
  mutation LoginMutation($email: EmailAddress!, $password: String!, $afterAuth: AfterAuthInput) {
    login(email: $email, password: $password, afterAuth: $afterAuth) {
      success
      message
      navigate
    }
  }
`);

const logoutMutation = graphql(/* GraphQL */ `
  mutation LogoutMutation {
    logout {
      success
      message
    }
  }
`);

const registerMutation = graphql(/* GraphQL */ `
  mutation RegisterMutation($input: RegisterInput!) {
    register(input: $input) {
      success
      message
    }
  }
`);

const applyActionCodeMutation = graphql(/* GraphQL */ `
  mutation ApplyActionCodeMutation($oobCode: String!) {
    applyActionCode(oobCode: $oobCode) {
      success
      message
      navigate
    }
  }
`);

const signInWithEmailLinkMutation = graphql(/* GraphQL */ `
  mutation SignInWithEmailLinkMutation($email: EmailAddress!, $link: SignInLink!) {
    loginWithEmailLink(email: $email, link: $link) {
      success
      message
    }
  }
`);

const sendVerificationEmailMutation = graphql(/* GraphQL */ `
  mutation SendVerificationEmailMutation {
    sendVerificationEmail {
      success
      message
    }
  }
`);

const resetPasswordMutation = graphql(/* GraphQL */ `
  mutation ResetPasswordMutation($newPassword: Password!, $oobCode: String!) {
    resetPassword(newPassword: $newPassword, oobCode: $oobCode) {
      success
      message
    }
  }
`);

const sendResetPasswordMutation = graphql(/* GraphQL */ `
  mutation SendResetPasswordMutation($email: EmailAddress!) {
    sendResetPassword(email: $email) {
      success
      message
    }
  }
`);

const verifyPasswordResetCodeMutation = graphql(/* GraphQL */ `
  mutation VerifyPasswordResetCodeMutation($oobCode: String!) {
    verifyPasswordResetCode(oobCode: $oobCode) {
      success
      message
    }
  }
`);

const requestNewInviteMutation = graphql(/* GraphQL */ `
  mutation RequestNewInviteMutation($email: EmailAddress!) {
    requestNewInvite(email: $email) {
      success
      message
    }
  }
`);
