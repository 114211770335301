import React, { createContext, useCallback, useContext, useState } from 'react';
import * as RadixToast from '@radix-ui/react-toast';

import { cn } from '../utils';

type TypeToast = { title: string; description: string; type?: string; testId?: string };

function Toast(props: TypeToast & { index: number }) {
  const { title, description, index, testId } = props;
  return (
    <RadixToast.Root
      className="grid items-start gap-x-3 rounded-sm bg-white p-3 shadow-dropdown"
      key={index}
      data-testid={testId}
    >
      <RadixToast.Title className="mb-1 text-xs font-medium">{title}</RadixToast.Title>
      <RadixToast.Description className="ToastDescription m-0 text-xs leading-3">{description}</RadixToast.Description>
    </RadixToast.Root>
  );
}

const ToastContext = createContext<null | ((toast: TypeToast) => void)>(null);

export function useToast() {
  const toast = useContext(ToastContext);
  if (toast == null) throw 'No toast context';
  return toast;
}

export function ToastProvider({ children, className }: { children: React.ReactNode; className?: string }) {
  const [toasts, setToasts] = useState<TypeToast[]>([]);

  const addToast = useCallback((toast: TypeToast) => {
    setToasts(toasts => [...toasts, toast]);
  }, []);

  return (
    <RadixToast.Provider>
      <ToastContext.Provider value={addToast}>{children}</ToastContext.Provider>
      {toasts.map((toast, index) => (
        <Toast key={index} {...toast} index={index} />
      ))}
      <RadixToast.Viewport
        className={cn(
          'fixed bottom-0 right-0 z-50 m-0 flex w-96 max-w-fit list-none flex-col gap-2 pr-2 outline-none',
          className
        )}
      />
    </RadixToast.Provider>
  );
}
