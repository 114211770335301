import { type InputMaybe, type PaginationInfo } from '@aether/client-graphql/generated/graphql';
import {
  ApolloClient,
  from,
  InMemoryCache,
  type DocumentNode,
  type FieldFunctionOptions,
  type FieldPolicy,
  type NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import { customFetch } from './custom-fetch';
import { queriesProgressLink } from './queries-progress-link';

// to be populated by playwright

let client: ApolloClient<NormalizedCacheObject>;
let proxyClient: ApolloClient<NormalizedCacheObject>;

export function getApolloClient(useProxy = true) {
  if (useProxy && proxyClient) return proxyClient;
  if (client) return client;
  const cacheBustToken = localStorage.getItem('cacheBustToken');

  const uploadLink = createUploadLink({
    uri: useProxy ? `${process.env.NX_BFF_PROXY_URL}/graphql` : `${process.env.NX_GRAPHQL_API_URL}`,
    headers: {
      ...(cacheBustToken ? { 'x-cache-bust': cacheBustToken } : {}),
    },
    fetch: customFetch as any,
    credentials: 'include',
  });

  const persistedQueryLink = createPersistedQueryLink({
    generateHash: (document: DocumentNode & { __meta__?: { hash: string } }) => document.__meta__?.hash || '',
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(graphQLError => console.error(`[GraphQL error]`, JSON.stringify(graphQLError)));
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            strategies: customOffsetLimitPagination(),
            strategyGroups: customOffsetLimitPagination(),
            strategySummariesLight: customOffsetLimitPagination(),
          },
        },
        StrategyConfig: { merge: true },
        SustainabilityScores: { merge: true },
        SustainabilityScoresEsg: { merge: true },
        SustainabilityEnvironmentScores: { merge: true },
        SustainabilityGovernanceScores: { merge: true },
        SustainabilitySocialScores: { merge: true },
        SustainabilityScoresUngc: { merge: true },
        SustainabilityImpactScores: { merge: true },
        SustainabilitySdgImpactSummary: { merge: true },
        SustainabilitySdgImpactScores: { merge: true },
        SustainabilitySdgImpactCategoryScores: { merge: true },
        SustainabilityHistograms: { merge: true },
        DiversityScores: { merge: true },
        DiversityCompany: { merge: true },
        DiversityCompanySexuality: { merge: true },
        DiversityCompanyFamily: { merge: true },
        DiversityCompanyDisability: { merge: true },
        DiversityCompanyDei: { merge: true },
        DiversityCompanyAge: { merge: true },
        DiversityCompanyEducation: { merge: true },
        DiversityCompanyRace: { merge: true },
        DiversityCompanyGender: { merge: true },
        DiversityExecutive: { merge: true },
        DiversityExecutiveNationality: { merge: true },
        DiversityExecutiveAge: { merge: true },
        DiversityExecutiveEducation: { merge: true },
        DiversityExecutiveRace: { merge: true },
        DiversityExecutiveGender: { merge: true },
        DiversityBoard: { merge: true },
        DiversityBoardNationality: { merge: true },
        DiversityBoardAge: { merge: true },
        DiversityBoardEducation: { merge: true },
        DiversityBoardRace: { merge: true },
        DiversityBoardGender: { merge: true },
        ScreenConfig: { merge: true },
        FactorUniverse: { merge: true },
        DateBasedTrigger: { merge: true },
        InvestmentUniverseConfig: { merge: true },
        BenchmarkConfigComputation: { merge: true },
        BacktesterSignals: { merge: true },
        BacktesterExecution: { merge: true },
        Backtester: { merge: true },
        PolicyParamsAlphaModel: { merge: true },
        CostModel: { merge: true },
        PolicyParamsPostProcess: { merge: true },
        PolicyParams: { merge: true },
        Policy: { merge: true },
        RiskModelFactorisation: { merge: true },
        RiskModel: { merge: true },
        ObjectiveTerms: { merge: true },
        RebalanceConstraint: { merge: true },
        RebalancePostProcess: { merge: true },
        PortfolioRebalance: { merge: true },
        ConfigRebalance: { merge: true },
        ConfigOutput: { merge: true },
        Config: { merge: true },
      },
      possibleTypes: {},
    }),
    link: from([persistedQueryLink, errorLink, queriesProgressLink, uploadLink]),
  });
  if (useProxy) {
    proxyClient = apolloClient;
  } else {
    client = apolloClient;
  }
  return apolloClient;
}

/**
 * Custom offset-based pagination policy supporting our default paginated response shape and query input shape.
 *
 * @see https://www.apollographql.com/docs/react/pagination/core-api
 */
function customOffsetLimitPagination<
  TIncoming extends PaginatedResponse = PaginatedResponse,
  TArgs extends PaginatedInput = PaginatedInput,
>(keyArgs: FieldPolicy['keyArgs'] = false) {
  const policy: FieldPolicy<TIncoming, TIncoming, TIncoming, FieldFunctionOptions<TArgs>> = {
    keyArgs,
    merge(existing, incoming, { args }) {
      const offset = args?.input?.offset || 0;
      const mergedData = existing?.data?.slice(0) || [];
      if (incoming?.data) {
        const newData = incoming.data;
        for (let i = 0; i < newData.length; ++i) {
          mergedData[offset + i] = newData[i];
        }
      }
      return {
        ...incoming,
        data: mergedData,
      };
    },
  };
  return policy;
}

type PaginatedResponse<T = unknown> = {
  data: T[];
  pagination: PaginationInfo;
};

type PaginatedInput = {
  input?: InputMaybe<
    {
      limit?: number;
      offset?: number;
    } & Record<string, unknown>
  >;
};
