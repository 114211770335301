import { useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { useAbilityContext, useAuth } from '../utils/auth';

const unAuthRoutes = ['/auth/*', '/funds/*', '/strategies/*', '/dragon'];

export const AuthController = () => {
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const ability = useAbilityContext();

  useEffect(() => {
    if (user && isAuthenticated) {
      if (!location.pathname.includes('/auth')) {
        if (!user.emailVerified) navigate(`/auth/email-verification`);
        if (ability.cannot('read', 'App')) navigate(`/auth/unauthorized`);
      }
      if (!user.passwordSet && location.pathname !== '/account/complete-signup') navigate(`/account/complete-signup`);
    } else if (!isUnAuthRoute(location.pathname))
      navigate(`/auth/login?redirectTo=${encodeURIComponent(location.pathname + location.search + location.hash)}`, {
        replace: true,
      });
  }, [user, location, navigate, ability, isAuthenticated]);

  return null;
};

const isUnAuthRoute = (pathname: string) => unAuthRoutes.some(path => Boolean(matchPath({ path }, pathname)));
