import { Login } from '@/pages/Auth/Login/Login';

type DemoPageType =
  | 'performance'
  | 'sustainability'
  | 'composition'
  | 'holdings'
  | 'drawdown'
  | 'turnover'
  | 'attribution';

export const DemoPage = ({ type }: { type: DemoPageType }) => {
  return (
    <div
      className="h-full w-full overflow-hidden bg-cover"
      style={{ backgroundImage: `url(/images/demo/${type}.jpg)` }}
    >
      <div>
        <Login />
      </div>
    </div>
  );
};
