import { blockedCountriesISO } from '@aether/taxonomy';
import { createMongoAbility, type MongoAbility, type MongoQuery } from '@casl/ability';
import * as z from 'zod';

export const emailSchema = z
  .string()
  .trim()
  .toLowerCase()
  .email()
  .regex(
    /^(.+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!aol.com)(?!googlemail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)(?!icloud.com)(?!zoho.com)(?!mail.com)(?!protonmail.com)(?!outlook.com)(?!gmx.com)(?!yandex.com).+)?$/,
    {
      message: 'Email provider not supported, please use a business email address.',
    }
  );

export const passwordSchema = z
  .string()
  .min(8, { message: 'Must be at least 8 characters long' })
  .regex(/^(?=.*[a-z])/, { message: 'Must include at least one lowercase letter' })
  .regex(/^(?=.*[A-Z])/, { message: 'Must include at least one uppercase letter' })
  .regex(/^(?=.*\d)/, { message: 'Must include at least one digit' })
  .regex(/^(?=.*[`~!@#$%^&*()\-_=+\]}[{\\|;:'",<.>/?£€ ])/, {
    message: 'Must include at least one special character',
  });

export const displayNameSchema = z.string().trim().min(5, { message: 'Must be at least 5 characters long' });

export const professionSchema = z.literal('Professional', {
  errorMap: issue => {
    if ('received' in issue) {
      if (issue.received === '') {
        return { message: 'Please provide a profession.' };
      }
    }
    if (issue.code === 'invalid_literal') {
      return { message: 'We currently only support professional clients.' };
    }
    return { message: issue.message || 'Please provide a valid profession.' };
  },
});

export const accountCountrySchema = z
  .string()
  .trim()
  .toUpperCase()
  .refine(val => val, { message: 'Please provide a country' })
  .refine(val => val.length === 2, { message: 'Country code must be 2 characters' })
  .refine(val => !blockedCountriesISO.includes(val), { message: 'This country is not supported' });

export const declaredOrgSchema = z.string().trim().min(1, { message: 'Please provide an organization name' });
const AfterAuthActions = [
  'CreateStrategy',
  'DownloadFactsheet',
  'SeeAttribution',
  'ToggleFavourite',
  'CompareStrategies',
  'Rebalance',
] as const;

export const declaredCaptchaSchema = z.string().trim().min(1, { message: 'Please solve the captcha' });

export const signUpSchema = z.object({
  email: emailSchema,
  password: passwordSchema,
  displayName: displayNameSchema,
  profession: professionSchema,
  country: accountCountrySchema,
  declaredOrg: declaredOrgSchema,
  captchaResponse: declaredCaptchaSchema,
  afterAuth: z
    .object({
      action: z.enum(AfterAuthActions),
      data: z.any().optional(),
      navigate: z.string().optional(),
    })
    .optional(),
});

export const oobCodeSchema = z.string();

/**
 * User roles order in this list is important because it's used to define the role priority
 * where the first role in the list has the LOWEST priority and the last role in the list
 * has the HIGHEST priority.
 *
 * The first role in the list can be overridden by any roles following it and the last
 * role in the list overrides any roles preceding it.
 */
export const userRolesList = ['public', 'restricted', 'user'] as const;
export type UserRole = (typeof userRolesList)[number];
export const UserRoleSchema = z.enum(userRolesList);

export const Actions = ['read', 'create', 'update', 'delete', 'download', 'manage'] as const;
export type Action = (typeof Actions)[number];
export const Subjects = [
  'FactsheetPage',
  'Dashboard',
  'ContactForm',
  'FAQ',
  'ProfilePage',
  'SignupPage',
  'AdminPage',
  'DataManagementPage',
  'CompareStrategiesPage',
  'CompareStrategiesDate',
  'DashboardPage',
  'StrategiesPage',
  'StrategyDetailsPage',
  'StrategyDetailsHeader',
  'StrategyOverviewPage',
  'StrategyHoldingsPage',
  'StrategyTurnoverPage',
  'StrategyAttributionPage',
  'StrategyDrawdownPage',
  'StrategyConfigPage',
  'StrategyPerformancePage',
  'StrategyCompositionPage',
  'StrategySustainabilityPage',
  'AdvancedCreateStrategyPage',
  'UploadHoldingsPage',
  'UploadBooksPage',
  'CreateStrategyPage',
  'RebalancePortfolioPage',
  'StrategyDetailsBenchmarkSelector',
  'StrategyDetailsStrategySelector',
  'StrategyDetailsDateSelector',
  'StrategyDetailsCurrencySelector',
  'StrategiesList',
  'AnalyzePortfolioPage',
  'StrategyDetailsLiveMonitoring',
  'StrategyDetailsHoldingsExplainability',
  'StrategyDetailsHoldingsBenchmarkWeights',
  'StrategyStats',
  'StrategyDetailsAttributionDate',
  'Strategy',
  'Factsheet',
  'User',
  'UserAccount',
  'Table',
  'Chart',
  'App',
  'Favourites',
  'Backtest',
  'Attribution',
  'AIOverlay',
  'all',
] as const;
export type Subject = (typeof Subjects)[number];
// https://casl.js.org/v6/en/guide/define-rules#the-shape-of-raw-rule
export type Rule = {
  action: Action[]; // limiting to arrays to avoid graphql schema unions or scalars
  subject: Subject[]; // limiting to arrays to avoid graphql schema unions or scalars
  fields?: string[];
  conditions?: any;
  inverted?: boolean;
  reason?: string;
};
export const actionSchema = z.enum(Actions);
export const subjectSchema = z.enum(Subjects);

export const limitKeys = ['Backtest'] as const;
export const limitsSchema = z.record(z.enum(limitKeys), z.coerce.number());
export type Limits = z.infer<typeof limitsSchema>;
export const defaultLimit: Limits = { Backtest: 10 };

export const getUserAbility = (permissions: Rule[]) =>
  createMongoAbility<MongoAbility<[Action, Subject | { __typename?: Subject; [key: string]: any }], MongoQuery>>(
    permissions,
    { detectSubjectType: object => object.__typename || 'all' }
  );

export type AfterAuthAction = (typeof AfterAuthActions)[number];
export type AfterAuth = {
  action: AfterAuthAction;
  data?: any;
  navigate?: string;
};
