import { createBrowserRouter, Outlet, replace, type RouteObject } from 'react-router-dom';

import { ContactForm } from './components/Contact/ContactForm';
import Layout from './components/Layout/Layout';
import { PageHeader, PageTitle } from './components/PageHeader';
import { WhenI } from './components/Permissions/WhenI';
import { Action } from './pages/Auth/Action/Action';
import { EmailVerification } from './pages/Auth/EmailVerify/EmailVerification';
import { Forbidden } from './pages/Auth/Forbidden/Forbidden';
import { Login } from './pages/Auth/Login/Login';
import { Logout } from './pages/Auth/Logout/Logout';
import { PasswordResetRequest } from './pages/Auth/PasswordReset/PasswordResetRequest';
import { Register } from './pages/Auth/Register/Register';
import { NotFound } from './pages/Error/NotFound';
import { RouterError } from './pages/Error/RouterError';
import { Home } from './pages/Home';
import RootLayout from './pages/RootLayout';
import { DemoPage } from './pages/StrategyDetails/components/DemoPage';

// import { StrategyDetailsConfig } from './pages/StrategyDetails/pages/StrategyDetailsConfig/StrategyDetailsConfig';
// import { DividendYield } from './pages/StrategyDetails/pages/StrategyDetailsPerformance/DividendYield';
// import { RealizedPnl } from './pages/StrategyDetails/pages/StrategyDetailsPerformance/RealizedPnl/RealizedPnl';
// import { StrategyDetailsSustainabilityDiversity } from './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilityDiversity/StrategyDetailsSustainabilityDiversity';

function strategyDetailsRoutes(strategyType: 'fund' | 'strategy'): RouteObject[] {
  return [
    {
      index: true,
      loader: ({ request }) => {
        const url = new URL(request.url);
        return replace(`${url.pathname}/analytics${url.search}`);
      },
    },
    {
      path: 'analytics',
      id: `${strategyType}_analytics`,
      children: [
        {
          index: true,
          lazy: async () => {
            const { StrategyDetailsOverview } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsOverview/StrategyDetailsOverview'
            );
            const Component = () => <WhenI can="read" the="StrategyOverviewPage" show={<StrategyDetailsOverview />} />;
            Component.displayName = 'StrategyDetailsOverview';
            return {
              Component,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        {
          path: 'holdings',
          lazy: async () => {
            const { StrategyDetailsHoldings } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsHoldings/StrategyDetailsHoldings'
            );
            const Component = () => (
              <WhenI
                can="read"
                the="StrategyHoldingsPage"
                show={<StrategyDetailsHoldings />}
                otherwiseShow={<DemoPage type="holdings" />}
              />
            );
            Component.displayName = 'StrategyDetailsHoldings';
            return {
              Component,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        {
          path: 'holdings-enhanced',
          lazy: async () => {
            const { StrategyDetailsHoldingsEnhanced } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsHoldings/StrategyDetailsHoldingsEnhanced'
            );
            const Component = () => (
              <WhenI
                can="read"
                the="StrategyHoldingsPage"
                show={<StrategyDetailsHoldingsEnhanced />}
                otherwiseShow={<DemoPage type="holdings" />}
              />
            );
            Component.displayName = 'StrategyDetailsHoldingsEnhanced';
            return {
              Component,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        {
          path: 'turnover',
          lazy: async () => {
            const { StrategyDetailsTurnover } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsTurnover/StrategyDetailsTurnover'
            );
            const Component = () => (
              <WhenI
                can="read"
                the="StrategyTurnoverPage"
                show={<StrategyDetailsTurnover />}
                otherwiseShow={<DemoPage type="turnover" />}
              />
            );
            Component.displayName = 'StrategyDetailsTurnover';
            return {
              Component,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        {
          path: 'attribution',
          lazy: async () => {
            const { StrategyDetailsAttributionLayout } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsAttribution/StrategyDetailsAttributionLayout'
            );
            const Component = () => (
              <WhenI
                can="read"
                the="StrategyAttributionPage"
                show={<StrategyDetailsAttributionLayout />}
                otherwiseShow={<DemoPage type="attribution" />}
              />
            );
            Component.displayName = 'StrategyDetailsAttributionLayout';
            return {
              Component,
              ErrorBoundary: () => <RouterError />,
            };
          },

          children: [
            {
              path: 'overview',
              lazy: async () => {
                const { StrategyDetailsAttributionOverviewPage } = await import(
                  './pages/StrategyDetails/pages/StrategyDetailsAttribution/Overview/Overview'
                );
                return {
                  Component: StrategyDetailsAttributionOverviewPage,
                  ErrorBoundary: () => <RouterError />,
                };
              },
            },
            {
              path: 'return-decomposition',
              lazy: async () => {
                const { StrategyDetailsAttributionReturnDecompositionPage } = await import(
                  './pages/StrategyDetails/pages/StrategyDetailsAttribution/ReturnDecomposition/ReturnDecomposition'
                );
                return {
                  Component: StrategyDetailsAttributionReturnDecompositionPage,
                  ErrorBoundary: () => <RouterError />,
                };
              },
            },
          ],
        },
        {
          path: 'drawdown',
          lazy: async () => {
            const { StrategyDetailsDrawdown } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsDrawdown/StrategyDetailsDrawdown'
            );
            const Component = () => (
              <WhenI
                can="read"
                the="StrategyDrawdownPage"
                show={<StrategyDetailsDrawdown />}
                otherwiseShow={<DemoPage type="drawdown" />}
              />
            );
            Component.displayName = 'StrategyDetailsDrawdown';
            return {
              Component,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        // {
        //   path: 'rolling',
        //   element: <StrategyDetailsRisk />,
        // },
        // {
        //   path: 'config',
        //   element: <WhenI can="read" the="StrategyConfigPage" show={<StrategyDetailsConfig />} />,
        //
        // },
        {
          path: 'performance',
          lazy: async () => {
            const { StrategyDetailsPerformanceLayout } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsPerformance/StrategyDetailsPerformanceLayout'
            );
            const Component = () => (
              <WhenI
                can="read"
                the="StrategyPerformancePage"
                show={<StrategyDetailsPerformanceLayout />}
                otherwiseShow={<DemoPage type="performance" />}
              />
            );
            Component.displayName = 'StrategyDetailsPerformanceLayout';
            return {
              Component,
              ErrorBoundary: () => <RouterError />,
            };
          },

          children: [
            {
              path: 'overview',
              lazy: async () => {
                const { Overview } = await import('./pages/StrategyDetails/pages/StrategyDetailsPerformance/Overview');
                return {
                  Component: Overview,
                  ErrorBoundary: () => <RouterError />,
                };
              },
            },
            {
              path: 'monthly-returns',
              lazy: async () => {
                const { MonthlyReturns } = await import(
                  './pages/StrategyDetails/pages/StrategyDetailsPerformance/MonthlyReturns'
                );
                return {
                  Component: MonthlyReturns,
                  ErrorBoundary: () => <RouterError />,
                };
              },
            },
            // {
            //   path: 'realized-pnl',
            //   element: <RealizedPnl />,
            //
            // },
            // {
            //   path: 'dividend-yield',
            //   element: <DividendYield />,
            //
            // },
          ],
        },
        {
          path: 'composition',
          lazy: async () => {
            const { StrategyDetailsCompositionLayout } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsComposition/StrategyDetailsCompositionLayout'
            );
            const Component = () => (
              <WhenI
                can="read"
                the="StrategyCompositionPage"
                show={<StrategyDetailsCompositionLayout />}
                otherwiseShow={<DemoPage type="composition" />}
              />
            );
            Component.displayName = 'StrategyDetailsCompositionLayout';
            return {
              Component,
              ErrorBoundary: () => <RouterError />,
            };
          },

          children: [
            {
              path: 'countries',
              lazy: async () => {
                const { StrategyDetailsCountries } = await import(
                  './pages/StrategyDetails/pages/StrategyDetailsComposition/StrategyDetailsCountries'
                );
                return {
                  Component: StrategyDetailsCountries,
                  ErrorBoundary: () => <RouterError />,
                };
              },
            },
            {
              path: 'sectors',
              lazy: async () => {
                const { StrategyDetailsSectors } = await import(
                  './pages/StrategyDetails/pages/StrategyDetailsComposition/StrategyDetailsSectors'
                );
                return {
                  Component: StrategyDetailsSectors,
                  ErrorBoundary: () => <RouterError />,
                };
              },
            },
            // {
            //   path: 'products-and-services',
            //   lazy: async () => {
            //     const { ProductsAndServices } = await import(
            //       './pages/StrategyDetails/pages/StrategyDetailsComposition/StrategyDetailsProductsAndServices'
            //     );
            //     return {
            //       Component: ProductsAndServices,
            //       ErrorBoundary: () => <RouterError />,
            //     };
            //   },
            //
            // },
          ],
        },
      ],
    },
    {
      path: 'sustainability',
      id: `${strategyType}_sustainability`,
      lazy: async () => {
        const { StrategyDetailsSustainabilityLayout } = await import(
          './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilityLayout'
        );
        const Component = () => (
          <WhenI
            can="read"
            the="StrategySustainabilityPage"
            show={<StrategyDetailsSustainabilityLayout />}
            otherwiseShow={<DemoPage type="sustainability" />}
          />
        );
        Component.displayName = 'StrategyDetailsSustainabilityLayout';
        return {
          Component,
          ErrorBoundary: () => <RouterError />,
        };
      },

      children: [
        {
          index: true,
          lazy: async () => {
            const { StrategyDetailsSustainabilityESG } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilityESG/StrategyDetailsSustainabilityESG'
            );
            return {
              Component: StrategyDetailsSustainabilityESG,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        {
          path: 'ungc',
          lazy: async () => {
            const { StrategyDetailsSustainabilityUNGC } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilityUNGC/StrategyDetailsSustainabilityUNGC'
            );
            return {
              Component: StrategyDetailsSustainabilityUNGC,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        {
          path: 'climate',
          lazy: async () => {
            const { StrategyDetailsSustainabilityClimate } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilityClimate/StrategyDetailsSustainabilityClimate'
            );
            return {
              Component: StrategyDetailsSustainabilityClimate,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        {
          path: 'impact',
          lazy: async () => {
            const { StrategyDetailsSustainabilityImpact } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilityImpact/StrategyDetailsSustainabilityImpact'
            );
            return {
              Component: StrategyDetailsSustainabilityImpact,
              ErrorBoundary: () => <RouterError />,
            };
          },

          children: [
            {
              index: true,
              lazy: async () => {
                const { BySdg } = await import(
                  './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilityImpact/components/BySdg/BySdg'
                );
                return {
                  Component: BySdg,
                  ErrorBoundary: () => <RouterError />,
                };
              },
            },
            {
              path: 'stakeholder',
              lazy: async () => {
                const { ByStakeholder } = await import(
                  './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilityImpact/components/ByStakeholder/ByStakeholder'
                );
                return {
                  Component: ByStakeholder,
                  ErrorBoundary: () => <RouterError />,
                };
              },
            },
          ],
        },
        {
          path: 'regulatory-reporting/sfdr',
          lazy: async () => {
            const { Sfdr } = await import(
              './pages/StrategyDetails/pages/StrategyDetailsSustainability/StrategyDetailsSustainabilitySFDR/StrategyDetailsSustainabilitySFDR'
            );
            return {
              Component: Sfdr,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        // {
        //   path: 'diversity',
        //   element: <StrategyDetailsSustainabilityDiversity />,
        //
        // },
      ],
    },
    {
      path: 'factsheet',
      lazy: async () => {
        const { FactsheetPage, FundFactsheetPage } = await import('./pages/Factsheet');
        const Component = () => (
          <WhenI
            can="read"
            the="FactsheetPage"
            show={strategyType === 'fund' ? <FundFactsheetPage /> : <FactsheetPage />}
          />
        );
        Component.displayName = 'FactsheetPage';
        return {
          Component,
          ErrorBoundary: () => <RouterError />,
        };
      },
    },
    {
      path: 'rebalance',
      lazy: async () => {
        const { RebalancePortfolioLayout } = await import('./pages/RebalancePortfolio/RebalancePortfolioLayout');
        const Component = () => <WhenI can="read" the="RebalancePortfolioPage" show={<RebalancePortfolioLayout />} />;
        Component.displayName = 'RebalancePortfolio';
        return {
          Component,
          ErrorBoundary: () => <RouterError />,
        };
      },
      children: [
        {
          index: true,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return replace(`${url.pathname}/holdings${url.search}`);
          },
        },
        {
          path: 'holdings',
          lazy: async () => {
            const { RebalancePortfolioHoldings } = await import(
              './pages/RebalancePortfolio/RebalancePortfolioHoldings'
            );
            return {
              Component: RebalancePortfolioHoldings,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
        {
          path: 'metrics',
          lazy: async () => {
            const { RebalancePortfolioMetrics } = await import('./pages/RebalancePortfolio/RebalancePortfolioMetrics');
            return {
              Component: RebalancePortfolioMetrics,
              ErrorBoundary: () => <RouterError />,
            };
          },
        },
      ],
    },
  ];
}

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <RouterError />,
    children: [
      // Auth Routes
      {
        path: '/auth', // these routes don’t need authentication see @AuthController
        children: [
          {
            path: '/auth/login',
            element: <Login />,
            errorElement: <RouterError />,
          },
          {
            path: '/auth/logout',
            element: <Logout />,
            errorElement: <RouterError />,
          },
          {
            path: '/auth/unauthorized',
            element: <Forbidden />,
            errorElement: <RouterError />,
          },
          {
            path: '/auth/reset-password',
            element: <PasswordResetRequest />,
            errorElement: <RouterError />,
          },
          {
            path: '/auth/register',
            element: <Register />,
            errorElement: <RouterError />,
          },
          {
            path: '/auth/email-verification',
            element: <EmailVerification />,
            errorElement: <RouterError />,
          },
          {
            path: '/auth/action',
            element: <Action />,
            errorElement: <RouterError />,
          },
        ],
      },
      {
        path: '/factsheet/:portfolioId',
        lazy: async () => {
          const { FactsheetPage } = await import('./pages/Factsheet');
          const Component = () => <WhenI can="read" the="FactsheetPage" show={<FactsheetPage />} />;
          Component.displayName = 'FactsheetPage';
          return {
            Component,
            ErrorBoundary: () => <RouterError />,
          };
        },
      },
      {
        path: '/fund-factsheet/:fundId',
        lazy: async () => {
          const { FundFactsheetPage } = await import('./pages/Factsheet');
          const Component = () => <WhenI can="read" the="FactsheetPage" show={<FundFactsheetPage />} />;
          Component.displayName = 'FundFactsheetPage';
          return {
            Component,
            ErrorBoundary: () => <RouterError />,
          };
        },
      },
      // Home Route
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/*',
        element: <NotFound />,
      },
      {
        path: '/',
        element: <Layout />,
        children: [
          // Misc Routes
          {
            path: '/contact',
            element: <WhenI can="read" the="ContactForm" show={<ContactForm />} />,
            errorElement: <RouterError />,
          },
          {
            path: '/faq',
            lazy: async () => {
              const { FAQ } = await import('./pages/FAQ/FAQ');
              return {
                Component: FAQ,
                ErrorBoundary: () => <RouterError />,
              };
            },
          },
          // Account
          {
            path: '/account',
            lazy: async () => {
              const { AccountLayout } = await import('./pages/Auth/Account/AccountLayout');
              return {
                Component: AccountLayout,
                ErrorBoundary: () => <RouterError />,
              };
            },
            children: [
              {
                path: '/account/edit-profile',
                lazy: async () => {
                  const { EditProfile } = await import('./pages/Auth/Account/EditProfile/EditProfile');
                  const Component = () => (
                    <WhenI can="read" the="ProfilePage" show={<EditProfile isSignup={false} />} />
                  );
                  Component.displayName = 'EditProfile';
                  return {
                    Component,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              {
                path: '/account/complete-signup',
                lazy: async () => {
                  const { EditProfile } = await import('./pages/Auth/Account/EditProfile/EditProfile');
                  const Component = () => <WhenI can="read" the="SignupPage" show={<EditProfile isSignup={true} />} />;
                  Component.displayName = 'EditProfile';
                  return {
                    Component,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
            ],
          },
          // Admin Routes
          {
            path: '/admin',
            lazy: async () => {
              const { AdminLayout } = await import('./pages/Admin/AdminLayout');
              const Component = () => <WhenI can="read" the="AdminPage" show={<AdminLayout />} />;
              Component.displayName = 'AdminLayout';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
            children: [
              {
                path: '/admin/all-configs',
                lazy: async () => {
                  const { Strategies } = await import('./pages/Admin/pages/Strategies/Strategies');
                  return {
                    Component: Strategies,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              {
                path: '/admin/user-management',
                lazy: async () => {
                  const { ManageUsers } = await import('./pages/Admin/pages/Users/ManageUsers');
                  return {
                    Component: ManageUsers,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              {
                path: '/admin/factsheet-templates',
                element: <Outlet />,
                errorElement: <RouterError />,
                children: [
                  {
                    path: '/admin/factsheet-templates',
                    lazy: async () => {
                      const { FactsheetTemplates } = await import(
                        './pages/Admin/pages/FactsheetTemplates/FactsheetTemplates'
                      );
                      return {
                        Component: FactsheetTemplates,
                        ErrorBoundary: () => <RouterError />,
                      };
                    },
                  },
                  {
                    path: '/admin/factsheet-templates/new',
                    lazy: async () => {
                      const { NewFactsheetTemplate } = await import(
                        './pages/Admin/pages/FactsheetTemplates/NewFactsheetTemplate'
                      );
                      return {
                        Component: NewFactsheetTemplate,
                        ErrorBoundary: () => <RouterError />,
                      };
                    },
                  },
                  {
                    path: '/admin/factsheet-templates/:id',
                    lazy: async () => {
                      const { EditFactsheetTemplate } = await import(
                        './pages/Admin/pages/FactsheetTemplates/EditFactsheetTemplate'
                      );
                      return {
                        Component: EditFactsheetTemplate,
                        ErrorBoundary: () => <RouterError />,
                      };
                    },
                  },
                ],
              },
            ],
          },
          // Data Management
          {
            path: '/data-management',
            lazy: async () => {
              const { DataManagementLayout } = await import('./pages/DataManagement/DataManagementLayout');
              const Component = () => <WhenI can="read" the="DataManagementPage" show={<DataManagementLayout />} />;
              Component.displayName = 'DataManagementLayout';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
            children: [
              {
                path: '/data-management/custom-assets',
                lazy: async () => {
                  const { CustomAssetSeries } = await import('./pages/DataManagement/CustomAssetSeries');
                  return {
                    Component: CustomAssetSeries,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              // Custom Variables
              {
                path: '/data-management/custom-variables',
                lazy: async () => {
                  const { CustomVariableLayout } = await import(
                    './pages/DataManagement/CustomVariables/CustomVariableLayout'
                  );
                  return {
                    Component: CustomVariableLayout,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
                children: [
                  {
                    path: '/data-management/custom-variables',
                    lazy: async () => {
                      const { CustomVariables } = await import(
                        './pages/DataManagement/CustomVariables/CustomVariables'
                      );
                      return {
                        Component: CustomVariables,
                        ErrorBoundary: () => <RouterError />,
                      };
                    },
                  },
                ],
              },
              {
                path: '/data-management/strategies',
                lazy: async () => {
                  const { ManageStrategiesLayout } = await import(
                    './pages/DataManagement/Strategy/ManageStrategiesLayout'
                  );
                  return {
                    Component: ManageStrategiesLayout,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
                children: [
                  // Strategies
                  {
                    path: '/data-management/strategies',
                    lazy: async () => {
                      const { ManageStrategies } = await import('./pages/DataManagement/Strategy/ManageStrategies');
                      return {
                        Component: ManageStrategies,
                        ErrorBoundary: () => <RouterError />,
                      };
                    },
                  },
                  // Strategy groups
                  {
                    path: '/data-management/strategies/groups',
                    lazy: async () => {
                      const { ManageGroups } = await import('./pages/DataManagement/Strategy/ManageGroups');
                      return {
                        Component: ManageGroups,
                        ErrorBoundary: () => <RouterError />,
                      };
                    },
                  },
                ],
              },
              // Universes
              {
                path: '/data-management/universes',
                lazy: async () => {
                  const { ManageUniverses } = await import('./pages/DataManagement/Universe/ManageUniverse');
                  return {
                    Component: ManageUniverses,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
                children: [
                  // List
                  {
                    index: true,
                    lazy: async () => {
                      const { ListUniverses } = await import('./pages/DataManagement/Universe/ListUniverses');
                      return {
                        Component: ListUniverses,
                        ErrorBoundary: () => <RouterError />,
                      };
                    },
                  },
                  // Create, Edit, Clone
                  {
                    path: '/data-management/universes/:action/:universeId?',
                    errorElement: <RouterError />,
                    lazy: () => import('./pages/DataManagement/Universe/EditUniverse'),
                  },
                ],
              },
            ],
          },
          // Compare Strategies Routes
          {
            path: '/strategies/compare',
            lazy: async () => {
              const { CompareStrategies } = await import('./pages/CompareStrategies/CompareStrategies');
              const Component = () => <WhenI can="read" the="CompareStrategiesPage" show={<CompareStrategies />} />;
              Component.displayName = 'CompareStrategies';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
            children: [
              {
                path: '/strategies/compare/financial',
                lazy: async () => {
                  const { Financial } = await import('./pages/CompareStrategies/Financial');
                  return {
                    Component: Financial,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              {
                path: '/strategies/compare/sustainability',
                lazy: async () => {
                  const { Sustainability } = await import('./pages/CompareStrategies/Sustainability');
                  return {
                    Component: Sustainability,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
            ],
          },
          {
            path: '/strategy/upload-holdings',
            lazy: async () => {
              const { UploadHoldings } = await import('./pages/StrategyUploads/UploadHoldings');
              const Component = () => <WhenI can="read" the="UploadHoldingsPage" show={<UploadHoldings />} />;
              Component.displayName = 'UploadHoldings';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
          },
          {
            path: '/strategy/upload-books',
            lazy: async () => {
              const { UploadBooks } = await import('./pages/StrategyUploads/UploadBooks');
              const Component = () => <WhenI can="read" the="UploadBooksPage" show={<UploadBooks />} />;
              Component.displayName = 'UploadBooks';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
          },
          {
            path: '/strategy/create',
            lazy: async () => {
              const { CreateStrategyLayout } = await import('./pages/CreateStrategy/CreateStrategyLayout');
              const Component = () => <WhenI can="read" the="CreateStrategyPage" show={<CreateStrategyLayout />} />;
              Component.displayName = 'CreateStrategy';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
            children: [
              {
                index: true,
                loader: ({ request }) => {
                  const url = new URL(request.url);
                  return replace(`/strategy/create/universe-construction${url.search}`);
                },
              },
              {
                path: 'universe-construction',
                lazy: async () => {
                  const { UniverseConstruction } = await import(
                    './pages/CreateStrategy/pages/UniverseConstruction/UniverseConstruction'
                  );
                  return {
                    Component: UniverseConstruction,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              {
                path: 'financial-objectives',
                lazy: async () => {
                  const { FinancialObjectives } = await import(
                    './pages/CreateStrategy/pages/FinancialObjectives/FinancialObjectives'
                  );
                  return {
                    Component: FinancialObjectives,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              {
                path: 'sustainability',
                lazy: async () => {
                  const { Sustainability } = await import('./pages/CreateStrategy/pages/Sustainability/Sustainability');
                  return {
                    Component: Sustainability,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              {
                path: 'backtest',
                lazy: async () => {
                  const { Backtest } = await import('./pages/CreateStrategy/pages/Backtest/Backtest');
                  return {
                    Component: Backtest,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
              {
                path: 'summary',
                lazy: async () => {
                  const { Summary } = await import('./pages/CreateStrategy/pages/Summary/Summary');
                  return {
                    Component: Summary,
                    ErrorBoundary: () => <RouterError />,
                  };
                },
              },
            ],
          },
          {
            path: '/strategy/advanced-create/:strategyId?',
            errorElement: <RouterError />,
            lazy: async () => {
              const {
                Component: AdvancedCreateStrategy,
                loader,
                AdvancedCreateStrategyError,
              } = await import('./pages/CreateStrategyAdvanced/AdvancedCreateStrategy');
              const Component = () => (
                <WhenI can="read" the="AdvancedCreateStrategyPage" show={<AdvancedCreateStrategy />} />
              );
              Component.displayName = AdvancedCreateStrategy.displayName;
              return {
                Component,
                loader,
                ErrorBoundary: AdvancedCreateStrategyError,
              };
            },
          },
          // Rebalance Strategy and Funds Route
          // {
          //   path: '/rebalance',
          //   lazy: async () => {
          //     const { RebalancePortfolioLayout } = await import('./pages/RebalancePortfolio/RebalancePortfolioLayout');
          //     const Component = () => (
          //       <WhenI can="read" the="RebalancePortfolioPage" show={<RebalancePortfolioLayout />} />
          //     );
          //     Component.displayName = 'RebalancePortfolio';
          //     return {
          //       Component,
          //       ErrorBoundary: () => <RouterError />,
          //     };
          //   },
          //   children: [
          //     {
          //       index: true,
          //       Component: null,
          //       ErrorBoundary: () => <RouterError />,
          //     },
          //     {
          //       path: ':portfolioId/holdings',
          //       lazy: async () => {
          //         const { RebalancePortfolioHoldings } = await import(
          //           './pages/RebalancePortfolio/RebalancePortfolioHoldings'
          //         );
          //         return {
          //           Component: RebalancePortfolioHoldings,
          //           ErrorBoundary: () => <RouterError />,
          //         };
          //       },
          //     },
          //     {
          //       path: ':portfolioId/metrics',
          //       lazy: async () => {
          //         const { RebalancePortfolioMetrics } = await import(
          //           './pages/RebalancePortfolio/RebalancePortfolioMetrics'
          //         );
          //         return {
          //           Component: RebalancePortfolioMetrics,
          //           ErrorBoundary: () => <RouterError />,
          //         };
          //       },
          //     },
          //   ],
          // },
          // Dashboard route
          {
            path: '/dashboard',
            loader: ({ request }) => {
              const url = new URL(request.url);
              return replace(`/dashboard/favourites${url.search}`);
            },
          },
          {
            path: '/dashboard/:subPath',
            lazy: async () => {
              const { Dashboard } = await import('./pages/Dashboard/Dashboard');
              const Component = () => (
                <WhenI
                  can="read"
                  the="DashboardPage"
                  show={<Dashboard />}
                  otherwiseShow={
                    <div>
                      <PageHeader>
                        <PageTitle className="my-4">Dashboard</PageTitle>
                      </PageHeader>
                      <div>
                        <Login />
                      </div>
                    </div>
                  }
                />
              );
              Component.displayName = 'Dashboard';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
          },
          // Strategies Route
          {
            path: '/strategies',
            loader: ({ request }) => {
              const url = new URL(request.url);
              return replace(`/dashboard/favourites${url.search}`);
            },
          },
          // Strategies Details Routes
          {
            path: '/strategies/:portfolioId',
            id: 'strategy',
            lazy: async () => {
              const { default: StrategyDetailsLayout } = await import('./pages/StrategyDetails/StrategyDetailsLayout');
              const Component = () => <WhenI can="read" the="StrategyDetailsPage" show={<StrategyDetailsLayout />} />;
              Component.displayName = 'StrategyDetailsPage';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
            children: strategyDetailsRoutes('strategy'),
          },
          {
            path: '/funds/:fundId',
            id: 'fund',
            lazy: async () => {
              const { default: FundDetailsLayout } = await import('./pages/StrategyDetails/FundDetailsLayout');
              const Component = () => <WhenI can="read" the="StrategyDetailsPage" show={<FundDetailsLayout />} />;
              Component.displayName = 'FundDetailsPage';
              return {
                Component,
                ErrorBoundary: () => <RouterError />,
              };
            },
            children: strategyDetailsRoutes('fund'),
          },
          // {
          //   path: '/analyze-portfolio',
          //   lazy: async () => {
          //     const { AnalyzePortfolioLayout } = await import('./pages/StrategyDetails/AnalyzePortfolioLayout');
          //     const Component = () => <WhenI can="read" the="AnalyzePortfolioPage" show={<AnalyzePortfolioLayout />} />;
          //     Component.displayName = 'AnalyzePortfolioPage';
          //     return {
          //       Component,
          //       ErrorBoundary: () => <RouterError />,
          //     };
          //   },
          //   children: [strategyDetailsRoutes.find(({ index }) => index) || strategyDetailsRoutes[0]],
          // },
        ],
      },
    ],
  },
]);
