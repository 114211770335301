import * as React from 'react';
import { Alert, Button, Card, TextField } from '@aether/ui';
import { emailSchema } from '@aether/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as z from 'zod';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { PageSeoTitle } from '@/components/PageSeoTitle';
import { useAuth } from '@/utils/auth';

import { AuthLink } from '../components/AuthLink';

const formSchema = z.object({
  email: emailSchema,
});

type FormValues = z.infer<typeof formSchema>;

export const PasswordResetRequest = () => {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);

  const { sendResetPasswordEmail } = useAuth();

  const [error, setError] = React.useState<any>();
  const [success, setSuccess] = React.useState<boolean | null>(null);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: params.get('email') || '',
    },
  });

  const resetPassword = async (values: FormValues) => {
    setSuccess(null);
    setError(undefined);
    try {
      await sendResetPasswordEmail(values.email);
      setSuccess(true);
    } catch (e: any) {
      setError(e);
      setSuccess(false);
    }
  };

  return (
    <Card
      variant="hero"
      title="Forgot Password?"
      data-testid="ac-reset-password"
      className="m-20 -mx-4 max-w-xl sm:mx-auto"
    >
      <PageSeoTitle title="Reset password" />
      <div className="flex flex-col gap-8">
        <p className="text-grey-500">
          If not{' '}
          <AuthLink to="/auth/login" className="whitespace-nowrap">
            Back to Sign In.
          </AuthLink>
        </p>
        <form className="form" onSubmit={handleSubmit(resetPassword)}>
          <div className="flex flex-1 flex-col gap-4">
            {!success && (
              <>
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      autoFocus
                      iconBefore="ic:outline-mail"
                      label="Enter your email address"
                      inputMode="email"
                      placeholder="you@company.com"
                      errorMessage={error?.message}
                    />
                  )}
                />
                <div>
                  <Button type="submit" loading={isSubmitting} loadingText="Sending...">
                    Send reset instructions
                  </Button>
                </div>
              </>
            )}

            {success && (
              <Alert level="info">
                <p>
                  If an account exists for <span className="font-bold">{watch('email')}</span>, an e-mail has been sent
                  to you to reset your password. Please follow the instructions in the e-mail and check your Junk folder
                  in case you haven't received it.
                </p>
              </Alert>
            )}

            {error && <ApiErrorAlert error={error} />}
          </div>
        </form>
      </div>
    </Card>
  );
};
