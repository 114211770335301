import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { Spinner } from '../Spinner';
import { cn } from '../utils';

export type CardProps = Omit<React.ComponentProps<'div'>, 'title'> &
  VariantProps<typeof cardClasses> & {
    title?: React.ReactNode;
    titleClassName?: string;
    footer?: React.ReactNode;
    footerClassName?: string;
    bodyClassName?: string;
    loading?: boolean;
  };

const cardClasses = cva(['relative flex flex-col overflow-hidden'], {
  variants: {
    variant: {
      bordered: 'gap-2 rounded border border-gray-200 bg-white px-4 py-5 shadow-card',
      hero: 'rounded border border-gray-200 bg-white p-6 shadow-card sm:p-12',
      flat: 'gap-4 border-b border-grey-100 pb-8',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'flat',
  },
});

export const Card = (props: CardProps) => {
  const {
    title,
    titleClassName,
    footer,
    footerClassName,
    bodyClassName,
    children,
    className,
    variant,
    loading,
    ...rest
  } = props;

  return (
    <section className={cn(cardClasses({ variant }), className)} data-loading={loading ? 'true' : undefined} {...rest}>
      {title && (
        <CardTitle variant={variant} className={titleClassName}>
          {title}
        </CardTitle>
      )}
      {loading === true ? (
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <CardBody variant={variant} className={bodyClassName}>
          {children}
        </CardBody>
      )}

      {footer && (
        <CardFooter variant={variant} className={footerClassName}>
          {footer}
        </CardFooter>
      )}
    </section>
  );
};

type CardTitleProps = React.ComponentProps<'header'> & VariantProps<typeof titleClasses>;

function CardTitle(props: CardTitleProps) {
  const { variant, children, className, ...rest } = props;
  return (
    <header className={cn(titleClasses({ variant }), className)} {...rest}>
      {children}
    </header>
  );
}
const titleClasses = cva('flex-0', {
  variants: {
    variant: {
      bordered: 'text-sm font-bold leading-6',
      hero: 'text-hero leading-14 tracking-tight',
      flat: 'text-base font-semibold leading-6',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'flat',
  },
});

type CardBodyProps = React.ComponentProps<'div'> & VariantProps<typeof bodyClasses>;

function CardBody(props: CardBodyProps) {
  const { children, className, variant, ...rest } = props;
  return (
    <div className={cn(bodyClasses({ variant }), className)} {...rest}>
      {children}
    </div>
  );
}

const bodyClasses = cva('flex-1', {
  variants: {
    variant: {
      bordered: '',
      hero: '',
      flat: '',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'flat',
  },
});

type CardFooterProps = React.ComponentProps<'footer'> & VariantProps<typeof footerClasses>;

function CardFooter(props: CardFooterProps) {
  const { children, className, variant, ...rest } = props;
  return (
    <footer className={cn(footerClasses({ variant }), className)} {...rest}>
      {children}
    </footer>
  );
}

const footerClasses = cva('flex-0', {
  variants: {
    variant: {
      bordered: '',
      hero: '',
      flat: '',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'flat',
  },
});
