import { useState } from 'react';
import { cn } from '@aether/ui';
import { Footer } from '@aether/ui/app-shared/Footer';
import { ButtonNew } from '@aether/ui/ButtonNew';
import { Link, Outlet } from 'react-router-dom';

import { useAuth } from '@/utils/auth';

import { OmniSearch } from '../OmniSearch/OmniSearch';
import { OmniSearchItemsProvider } from '../OmniSearch/OmniSearchItemsProvider';
import { PageLoader } from '../PageLoader/PageLoader';
import SideNavBar from './SideNavBar';

const SIDEBAR_STATE_KEY = 'isSideNavBarOpen';

export default function Layout() {
  const { isAuthenticated } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(['true', null].includes(localStorage.getItem(SIDEBAR_STATE_KEY)));

  const handleToggle = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    localStorage.setItem(SIDEBAR_STATE_KEY, String(newState));
  };

  return (
    <OmniSearchItemsProvider>
      <PageLoader />
      <div className="flex">
        <SideNavBar isOpen={isOpen} handleToggle={handleToggle} />
        <main
          className={cn(
            'grid w-full max-w-full grid-cols-1 grid-rows-[auto_1fr] bg-white',
            isAuthenticated ? 'grid-rows-[auto_1fr]' : 'grid-rows-[auto_auto_1fr]'
          )}
        >
          {!isAuthenticated && (
            <div className="centered-page sticky top-0 z-20 grid w-full grid-cols-[1fr_auto] gap-4 border-b border-gray-300 bg-white py-3">
              <OmniSearch />
              <div className="shrink-0 space-x-4">
                <ButtonNew variant="primary" iconBefore="ic:outline-add" asChild>
                  <Link to={`/auth/register`}>Create Account</Link>
                </ButtonNew>
                <ButtonNew variant="secondary" iconBefore="ic:outline-edit" asChild>
                  <Link to={`/auth/login`}>Sign In</Link>
                </ButtonNew>
              </div>
            </div>
          )}
          <Outlet />
          <Footer isAuthenticated={isAuthenticated} className="mb-0 mt-auto border-gray-300 bg-gray-100 p-2 px-3" />
        </main>
      </div>
    </OmniSearchItemsProvider>
  );
}
