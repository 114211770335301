import * as React from 'react';
import { cn } from '@aether/ui';
import { Slot } from '@radix-ui/react-slot';
import { Link } from 'react-router-dom';

type LinkProps = Parameters<typeof Link>[0];

export type AuthLinkProps =
  | ({ asChild?: false } & LinkProps)
  | ({ asChild: true } & Omit<React.ComponentProps<'a'>, 'ref'>);

export const AuthLink = React.forwardRef<HTMLAnchorElement, AuthLinkProps>(function AuthLink(props, ref) {
  const { className, asChild, ...rest } = props;
  const linkClassName = cn('font-medium text-black underline underline-offset-4', className);
  return asChild ? (
    <Slot ref={ref} {...rest} className={linkClassName} />
  ) : (
    <Link ref={ref} {...(rest as LinkProps)} className={linkClassName} />
  );
});
