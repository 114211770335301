import React from 'react';

import { ToastProvider } from './Toast';
import { TooltipProvider } from './Tooltip';

export function UiProvider({ children }: { children: React.ReactNode }) {
  return (
    <TooltipProvider>
      <ToastProvider>{children}</ToastProvider>
    </TooltipProvider>
  );
}
