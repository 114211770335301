import React from 'react';
import { cn } from '@aether/ui';
import { Slot } from '@radix-ui/react-slot';

import { useAuth } from '@/utils/auth';

interface PageHeaderProps extends React.ComponentProps<'header'> {
  loading?: boolean;
  stickyHeaderMenuRef?: React.RefObject<HTMLDivElement>;
}

export function PageHeader(props: PageHeaderProps) {
  const { isAuthenticated } = useAuth();
  const { children, className, stickyHeaderMenuRef, loading } = props;
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (stickyHeaderMenuRef?.current) setHeight(stickyHeaderMenuRef.current.getBoundingClientRect().top);
  }, [stickyHeaderMenuRef]);

  // When not authenticated, there is a log-in banner that takes up 114px of space
  // This could be done better but I guess is good enough for now
  const actualHeight = isAuthenticated ? height : height - 114;

  return (
    <header
      className={cn(`centered-page sticky z-10 border-b border-b-gray-300 bg-gray-50 pt-1`, className)}
      style={height ? { top: `-${actualHeight}px` } : {}}
      data-loading-header={loading ? 'true' : undefined}
    >
      {children}
    </header>
  );
}

interface PageTitleProps extends React.ComponentProps<'h1'> {
  asChild?: boolean;
}

export function PageTitle(props: PageTitleProps) {
  const { asChild, className, ...rest } = props;
  const Comp = asChild ? Slot : 'h1';
  return <Comp className={cn('text-2xl font-semibold tracking-tight', className)} {...rest} />;
}
