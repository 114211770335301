import { cva, type VariantProps } from 'class-variance-authority';

import { Icon } from '../Icon';
import { cn } from '../utils';

export type AlertProps = React.ComponentProps<'div'> &
  VariantProps<typeof alertStyle> & {
    noIcon?: boolean;
    icon?: string;
    title?: string;
  };

export function Alert(props: AlertProps) {
  const { level, className, children, noIcon = false, icon, title, ...rest } = props;
  return (
    <div className={cn(alertStyle({ level }), className)} {...rest}>
      {!noIcon && <Icon className={iconStyle({ level })} icon={icon || iconMap[level || 'info']} />}
      <div className="flex flex-1 flex-col gap-1">
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </div>
    </div>
  );
}

export type AlertTitleProps = React.ComponentProps<'div'>;

export function AlertTitle(props: AlertTitleProps) {
  const { className, ...rest } = props;
  return <div className={cn('font-bold', className)} {...rest} />;
}

const iconMap: Record<NonNullable<VariantProps<typeof alertStyle>['level']>, string> = {
  info: 'ic:outline-info',
  success: 'ic:outline-check-circle',
  warning: 'ic:outline-warning',
  error: 'ic:outline-error',
};

const alertStyle = cva('flex items-start justify-start gap-2 rounded border px-3 py-[11px] text-sm font-medium', {
  variants: {
    level: {
      info: 'border-portfolio-400 bg-portfolio-50 text-portfolio-700',
      warning: 'border-orange-400 bg-orange-50 text-orange-700',
      error: 'border-error-400 bg-error-50 text-error-700',
      success: 'border-success-400 bg-success-50 text-success-700',
    },
  },
  defaultVariants: {
    level: 'info',
  },
});

const iconStyle = cva('my-px text-[18px]', {
  variants: {
    level: {
      info: 'text-portfolio-500',
      success: 'text-success-500',
      warning: 'text-orange-500',
      error: 'text-error-500',
    },
  },
  defaultVariants: {
    level: 'info',
  },
});
