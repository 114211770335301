import * as React from 'react';
import { Button, Card, PasswordField, TextField } from '@aether/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import { ApiErrorAlert } from '@/components/ApiErrorAlert';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { PageSeoTitle } from '@/components/PageSeoTitle';
import { useAbilityContext, useAuth } from '@/utils/auth';

import { AuthLink } from '../components/AuthLink';

// We use loose validation for login on purpose, or accounts created before
// strong validation was introduced will not be able to login.
const formValuesSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, { message: 'Password is required.' }),
});

type FormValues = z.infer<typeof formValuesSchema>;

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { user } = useAuth();
  const { signInWithEmailAndPassword } = useAuth();
  const [error, setError] = React.useState<any>();
  const [afterAuth, setAfterAuth] = React.useState<{ isSigninIn: boolean; message?: string }>({ isSigninIn: false });
  const ability = useAbilityContext();

  const { handleSubmit, control } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: zodResolver(formValuesSchema),
    defaultValues: {
      email: params.get('email') || '',
      password: '',
    },
  });

  if (user && user.role !== 'public' && !afterAuth.isSigninIn && !afterAuth.message) {
    if (!user.emailVerified) {
      setTimeout(() => {
        navigate('/auth/email-verification', { replace: true });
      });
    } else {
      let redirectTo = params.get('redirectTo') || '/';

      if (ability.cannot('read', 'App')) {
        redirectTo = '/auth/unauthorized';
      }

      setTimeout(() => {
        navigate(redirectTo);
      });
    }
    return <></>;
  }

  const login = async ({ email, password }: FormValues) => {
    setAfterAuth({ isSigninIn: true });
    setError(undefined);
    signInWithEmailAndPassword({
      email,
      password,
      afterAuth: location?.state?.afterAuth,
    })
      .then(loginResponse => {
        if (loginResponse.data?.login.message && !loginResponse.data.login.success) {
          setAfterAuth({
            isSigninIn: false,
            message: loginResponse.data.login.message,
          });
        } else if (loginResponse.data?.login.navigate) {
          navigate(loginResponse.data.login.navigate);
        } else {
          setAfterAuth({ isSigninIn: false });
        }
      })
      .catch((error: unknown) => {
        setError(error);
        setAfterAuth({ isSigninIn: false });
      });
  };

  return (
    <Card variant="hero" title="Sign In." data-testid="ac-LoginPage" className="m-20 -mx-4 max-w-2xl sm:mx-auto">
      <PageSeoTitle title="Login" />
      <div className="flex flex-col gap-8">
        <p className="text-grey-500">
          Don't have an account yet?{' '}
          <AuthLink to="/auth/register" className="whitespace-nowrap">
            Request access.
          </AuthLink>
        </p>
        <form onSubmit={handleSubmit(login)}>
          <div className="flex flex-col gap-8 md:flex-row md:gap-12">
            <div className="flex flex-1 flex-col gap-4">
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextField
                      autoFocus
                      iconBefore="ic:outline-mail"
                      label="Email address"
                      inputMode="email"
                      placeholder="you@company.com"
                      errorMessage={error?.message}
                      {...field}
                    />
                  );
                }}
              />
              <div>
                <Controller
                  control={control}
                  name="password"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <PasswordField
                        label="Password"
                        iconBefore="ic:outline-key"
                        placeholder="Enter your password"
                        errorMessage={error?.message}
                        {...field}
                      />
                    );
                  }}
                />

                <Link to="/auth/reset-password" className="text-xs font-medium text-accent">
                  Forgot password?
                </Link>
              </div>
              <ApiErrorAlert error={error} />

              <div>
                <input type="submit" className="hidden" />
                <Button
                  type="submit"
                  className="w-full min-w-[100px] sm:w-auto"
                  data-testid="ac-signin-button"
                  loading={afterAuth.isSigninIn}
                  loadingText="Signing in..."
                >
                  Sign In
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div>
          <hr className="my-6 h-px w-36 border-none bg-grey-100" />
          <p className="text-grey-500">
            Learn more about{' '}
            <AuthLink asChild className="whitespace-nowrap">
              <a href="https://www.arabesque.com/ai/">Portfolio Dragon.</a>
            </AuthLink>
          </p>
        </div>
      </div>
      <ConfirmDialog
        alertDialogRootProps={{ open: !!afterAuth.message }}
        title="We encountered an error"
        body={afterAuth.message || 'The action you performed was unsuccessful, please try again.'}
        onConfirm={() => setAfterAuth({ isSigninIn: false })}
        onCancel={() => setAfterAuth({ isSigninIn: false })}
        confirmText="Continue"
        cancelProps={{ className: 'hidden' }}
      />
    </Card>
  );
};
